import React from 'react';

export default function SubMenu({
    examName,
    logoSrc,
    applyStatus, // Can be 'over', 'upcoming', or 'ongoing'
    applyLink,
    officialLink,
    calendarDate
}) {
    const getStatusColor = () => {
        switch (applyStatus) {
            case 'over':
                return 'text-red-500';
            case 'upcoming':
                return 'text-orange-500';
            case 'ongoing':
                return 'text-green-500';
            default:
                return '';
        }
    };

    const getStatusText = () => {
        switch (applyStatus) {
            case 'over':
                return '(Date is over)';
            case 'upcoming':
                return '(To be announced)';
            case 'ongoing':
                return '(Ongoing)';
            default:
                return '';
        }
    };

    return (
        <div className="submenu-item flex flex-col lg:flex-row justify-between items-center md:px-2 py-3 w-full lg:w-96">
            <div className="main-content w-full lg:w-4/5">
                <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-start gap-2 md:ml-1">
                    <img
                        src={logoSrc}
                        alt={`${examName} Logo`}
                        className={`exam-logo h-[55px] w-auto`}
                    />
                    <div className='flex flex-col lg:flex-wrap  lg:justify-start lg:items-start max-lg:gap-1'>
                        <h3 className="text-lg  font-semibold">{examName}</h3>
                        <span className={`text-sm ${getStatusColor()} whitespace-nowrap`}>
                            {getStatusText()}
                        </span>
                    </div>
                </div>
                <div className="submenu-links flex flex-col lg:flex-row justify-start">
                    <a
                        href={applyLink}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                        className={`apply-link py-2 rounded-lg transition ${applyStatus === 'over'
                            ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
                            : 'bg-blue-600 text-white hover:bg-blue-700'
                            }`} rel="noreferrer"
                    >
                        Apply Now
                    </a>
                    <a
                        href={officialLink}
                        onClick={(e) => e.stopPropagation()}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="official-link bg-green-600 text-white py-2 rounded-lg hover:bg-green-700 transition ml-2"
                    >
                        Official Website
                    </a>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full lg:w-1/3">
                <div className="relative">
                    <img
                        src={require("../../assets/svgs/calendar.jpg")}
                        alt="Calendar Logo"
                        className="calendar-logo w-16 h-16"
                    />
                    <span className="absolute text-sm font-semibold text-gray-700 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-[15%] whitespace-nowrap">
                        {calendarDate}
                    </span>
                </div>
            </div>
        </div>
    );
}